export const ITEMS_WIDGET_COMPONENT_IDS = {
  itemsRepeater: '#itemsRepeater',
  itemTitle: '#itemTitle',
  itemDescription: '#itemDescription',
  itemPrice: '#itemPrice',
  itemCurrency: '#itemCurrency',
  itemImage: '#itemImage',
  itemLabelsLayouter: '#labelLayouter1',

  // TODO - remove all unnecessary label elements when merge specs.restaurants.labelsLayouterWidget
  itemLabels: '#itemLabelsMultiStateBox',
  itemLabelsTextAndIconState: '#textAndIcon',
  itemLabelsSet2: '#itemLabelsSet2',

  itemLabelsTextIconLabel1: '#itemLabel1',
  itemLabelsTextIconLabel2: '#itemLabel2',
  itemLabelsTextIconLabel3: '#itemLabel3',
  itemLabelsTextIconLabel4: '#itemLabel4',
  itemLabelsTextIconLabel5: '#itemLabel5',

  itemLabelsTextIconLabelIcon1: '#labelicon1',
  itemLabelsTextIconLabelIcon2: '#labelicon2',
  itemLabelsTextIconLabelIcon3: '#labelicon3',
  itemLabelsTextIconLabelIcon4: '#labelicon4',
  itemLabelsTextIconLabelIcon5: '#labelicon5',

  itemLabelsTextIconLabelText1: '#labeltext1',
  itemLabelsTextIconLabelText2: '#labeltext2',
  itemLabelsTextIconLabelText3: '#labeltext3',
  itemLabelsTextIconLabelText4: '#labeltext4',
  itemLabelsTextIconLabelText5: '#labeltext5',

  itemLabelsTextIconContainer1: '#labelcontainer1',
  itemLabelsTextIconContainer2: '#labelcontainer2',
  itemLabelsTextIconContainer3: '#labelcontainer3',
  itemLabelsTextIconContainer4: '#labelcontainer4',
  itemLabelsTextIconContainer5: '#labelcontainer5',

  itemDivider: '#itemDivider',
  itemPriceVariantsBox: '#itemPriceVariantsBox',
  itemPriceVariantsRepeater: '#itemPriceVariants',
} as const;

export const LABELS_STATES = {
  TEXT_AND_ICON: 'textAndIcon',
  ICON_ONLY: 'iconOnly',
  TEXT_ONLY: 'textOnly',
};

export const LABELS_LIMIT = 5;
export const PRICE_VARIANTS_LIMIT = 20;
export const UNSELECTED_ITEMS_COMPONENT_IDS = Object.values(ITEMS_WIDGET_COMPONENT_IDS);
