import model from './model';
import { ItemsController } from './itemsController';
import { getPriceFormatter } from 'root/utils/currency';
import type { ItemsWidgetProps } from './types';
import { didChange } from 'root/utils/lifeCycle';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const priceFormatter = getPriceFormatter(flowAPI);
  const { isViewer } = flowAPI.environment;
  const experiments = flowAPI.experiments;
  const isLabelLayouterWidgetExperimentEnabled = experiments.enabled('specs.restaurants.labelsLayouterWidget');

  const itemsController = new ItemsController($w, isViewer, priceFormatter, isLabelLayouterWidgetExperimentEnabled);

  $widget.onPropsChanged((prevProps, nextProps) => {
    const {
      items,
      preset,
      shouldDisplayCurrency,
      shouldDisplayVariantCurrency,
      shouldDisplayZeroPrice,
      shouldDisplayPlaceholderImage,
      placeholderImage,
    }: ItemsWidgetProps = nextProps.data;
    const {
      items: prevItems,
      preset: prevPreset,
      shouldDisplayCurrency: prevShouldDisplayCurrency,
      shouldDisplayVariantCurrency: prevShouldDisplayVariantCurrency,
      shouldDisplayZeroPrice: prevShouldDisplayZeroPrice,
      shouldDisplayPlaceholderImage: prevShouldDisplayPlaceHolderImage,
      placeholderImage: prevPlaceholderImage,
    }: ItemsWidgetProps = prevProps.data;

    if (didChange(prevItems, items)) {
      itemsController.init(
        items,
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        shouldDisplayZeroPrice,
        shouldDisplayPlaceholderImage,
        placeholderImage
      );
    } else if (prevPreset !== preset) {
      itemsController.removeImagesFromDOMIfNeeded($w, preset);
    } else if (prevShouldDisplayCurrency !== shouldDisplayCurrency) {
      itemsController.updateCurrency(shouldDisplayCurrency);
    } else if (prevShouldDisplayVariantCurrency !== shouldDisplayVariantCurrency) {
      itemsController.updateVariantCurrency(shouldDisplayVariantCurrency);
    } else if (prevShouldDisplayZeroPrice !== shouldDisplayZeroPrice) {
      itemsController.updateShouldDisplayZeroPrice(shouldDisplayZeroPrice);
    } else if (
      placeholderImage !== prevPlaceholderImage ||
      shouldDisplayPlaceholderImage !== prevShouldDisplayPlaceHolderImage
    ) {
      itemsController.updatePlaceholderImage(placeholderImage, shouldDisplayPlaceholderImage);
    }
  });

  return {
    pageReady: async () => {
      const {
        items,
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        shouldDisplayZeroPrice,
        shouldDisplayPlaceholderImage,
        placeholderImage,
      }: ItemsWidgetProps = $widget.props.data;
      itemsController.init(
        items,
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        shouldDisplayZeroPrice,
        shouldDisplayPlaceholderImage,
        placeholderImage
      );
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
