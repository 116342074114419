import type { PopulatedMenu, ShowcasePopulatedItem } from 'root/types';
import type { HttpClientFactory } from './utils/types';
import { MenusClient } from './MenusClient';
import { SectionsClient } from './SectionsClient';
import { PopulatedItemsClient } from './ItemsClient';
import { populateMenu } from 'root/api/utils/populateMenu';
import { completeMissingEntities } from 'root/api/utils/completeMissingEntities';
import { getLabelsWithSvgElement } from 'root/utils/labels';

export const PopulatedMenuClient: HttpClientFactory<PopulatedMenu> = (httpClient) => {
  const MenusApi = MenusClient(httpClient);
  const SectionsApi = SectionsClient(httpClient);
  const PopulatedItemsApi = PopulatedItemsClient(httpClient);

  return {
    getAll: async ({ paging, isLabelLayouterWidgetExperimentEnabled }) => {
      const [menus, sections] = await Promise.all([MenusApi.getAll({ paging }), SectionsApi.getAll({ paging })]);

      const populatedItems = await PopulatedItemsApi.getAll({ paging });

      await completeMissingEntities(
        menus.data,
        sections,
        SectionsApi.getAll,
        populatedItems,
        PopulatedItemsApi.getAll,
        paging?.limit
      );

      const populatedItemsWithLabelsSvg = isLabelLayouterWidgetExperimentEnabled
        ? await Promise.all(
            (populatedItems?.data || []).map(async (populatedItem) => ({
              ...populatedItem,
              labels: await getLabelsWithSvgElement(populatedItem.labels),
            }))
          )
        : populatedItems?.data;

      return {
        data: menus.data.map((menu) =>
          populateMenu(menu, sections.data, (populatedItemsWithLabelsSvg ?? []) as ShowcasePopulatedItem[])
        ) as PopulatedMenu[],
      };
    },
  };
};
