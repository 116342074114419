import { ITEMS_WIDGET_COMPONENT_IDS, LABELS_LIMIT, LABELS_STATES } from './consts';
import { getLabelCompElementIds, getPlaceholderImage, setImageDataOrRemoveFromDOMIfNeeded } from './utils';
import type { Preset, ShowcasePopulatedItem } from 'root/types';
import { ELEMENT_PROPERTY } from 'root/utils/consts';
import type { I$W } from '@wix/yoshi-flow-editor';
import { setElementDataAndCollapseIfNeeded } from 'root/utils/setElementDataAndCollapseIfNeeded';
import { setPriceContainer } from 'root/utils/priceDisplay';
import { DEFAULT_PLACEHOLDER_IMAGE } from 'root/assets/images';

export class ItemsController {
  constructor(
    private $w: I$W,
    private isViewer?: boolean,
    private priceFormatter?: (price: string, shouldDisplayCurrency: boolean) => string | undefined,
    isLabelLayouterWidgetExperimentEnabled?: boolean
  ) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(
      async (
        $item: I$W,
        itemData: ShowcasePopulatedItem & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayVariantCurrency: boolean;
          shouldDisplayZeroPrice: boolean;
          shouldDisplayPlaceholderImage: boolean;
          placeholderImage?: string;
        }
      ) => {
        const itemHasPriceVariants = (itemData.priceVariants?.variants || []).length > 0;
        const itemHasNoPrice = Number(itemData.priceInfo?.price || '0.00') === 0;

        $item(ITEMS_WIDGET_COMPONENT_IDS.itemTitle).text = itemData.name;

        setElementDataAndCollapseIfNeeded(
          $item,
          ITEMS_WIDGET_COMPONENT_IDS.itemDescription,
          ELEMENT_PROPERTY.TEXT,
          itemData.description
        );

        setImageDataOrRemoveFromDOMIfNeeded(
          $item,
          itemData,
          itemData.preset,
          getPlaceholderImage({
            shouldDisplayPlaceholderImage: itemData.shouldDisplayPlaceholderImage,
            placeholderImage: itemData.placeholderImage || DEFAULT_PLACEHOLDER_IMAGE,
          }),
          this.isViewer
        );

        const hasLabels = itemData.labels?.length;

        const labelsLayouterElement = $item(ITEMS_WIDGET_COMPONENT_IDS.itemLabelsLayouter);
        (!isLabelLayouterWidgetExperimentEnabled || !hasLabels) && (await labelsLayouterElement.collapse?.());

        const labelsElement = $item(ITEMS_WIDGET_COMPONENT_IDS.itemLabels);
        (isLabelLayouterWidgetExperimentEnabled || !hasLabels) && (await labelsElement.delete?.());

        if (hasLabels) {
          if (isLabelLayouterWidgetExperimentEnabled) {
            await labelsLayouterElement.expand();
            labelsLayouterElement.data = {
              labels: itemData.labels,
            };
          } else {
            labelsElement.deleted && (await labelsElement.restore());
            this.initLabels($item, itemData);
          }
        }

        const priceVariantsContainer = $item(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsBox);
        const priceContainer = $item(ITEMS_WIDGET_COMPONENT_IDS.itemPrice);
        const priceCurrencyContainer = $item(ITEMS_WIDGET_COMPONENT_IDS.itemCurrency);
        priceCurrencyContainer.delete();

        if (!itemHasPriceVariants) {
          await priceVariantsContainer.delete();
          await priceContainer.delete();
          if (!itemHasNoPrice || itemData.shouldDisplayZeroPrice) {
            await priceContainer.restore();
            priceContainer.text = this.priceFormatter?.(
              itemData.priceInfo?.price || '0.00',
              itemData.shouldDisplayCurrency
            );
          }
        } else {
          await priceContainer.delete();
          await priceVariantsContainer.delete();
          await priceVariantsContainer.restore();
          $item(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsRepeater).data = {
            priceVariants: itemData.priceVariants?.variants,
            shouldDisplayVariantCurrency: itemData.shouldDisplayVariantCurrency,
            shouldDisplayZeroPrice: itemData.shouldDisplayZeroPrice,
          };
        }
      }
    );
  }

  updateCurrency(shouldDisplayCurrency: boolean) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(
      async (
        $item: I$W,
        itemData: ShowcasePopulatedItem & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
        }
      ) => {
        const priceContainer = $item(ITEMS_WIDGET_COMPONENT_IDS.itemPrice);
        priceContainer.text = this.priceFormatter?.(itemData.priceInfo?.price || '0.00', shouldDisplayCurrency);
      }
    );
  }

  updateVariantCurrency(shouldDisplayVariantCurrency: boolean) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(async ($item: I$W) => {
      $item(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsRepeater).data.shouldDisplayVariantCurrency =
        shouldDisplayVariantCurrency;
    });
  }

  updatePlaceholderImage(placeholderImage?: string, shouldDisplayPlaceholderImage?: boolean) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(
      async (
        $item: I$W,
        itemData: ShowcasePopulatedItem & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayVariantCurrency: boolean;
          shouldDisplayZeroPrice: boolean;
          shouldDisplayPlaceholderImage: boolean;
          placeholderImage?: string;
        }
      ) => {
        setImageDataOrRemoveFromDOMIfNeeded(
          $item,
          itemData,
          itemData.preset,
          getPlaceholderImage({
            placeholderImage: placeholderImage || DEFAULT_PLACEHOLDER_IMAGE,
            shouldDisplayPlaceholderImage: !!shouldDisplayPlaceholderImage,
          }),
          this.isViewer
        );
      }
    );
  }

  updateShouldDisplayZeroPrice(shouldDisplayZeroPrice: boolean) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(
      async (
        $item: I$W,
        itemData: ShowcasePopulatedItem & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayZeroPrice: boolean;
        }
      ) => {
        const itemHasPriceVariants = (itemData.priceVariants?.variants || []).length > 0;

        if (!itemHasPriceVariants) {
          setPriceContainer(
            $item,
            ITEMS_WIDGET_COMPONENT_IDS.itemPrice,
            itemData.priceInfo?.price,
            shouldDisplayZeroPrice,
            itemData.shouldDisplayCurrency,
            this.priceFormatter
          );
        } else {
          this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater)?.onItemReady?.(async ($item_: I$W) => {
            $item_(ITEMS_WIDGET_COMPONENT_IDS.itemPriceVariantsRepeater).data.shouldDisplayZeroPrice =
              shouldDisplayZeroPrice;
          });
        }
      }
    );
  }

  init(
    items: ShowcasePopulatedItem[],
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    shouldDisplayZeroPrice: boolean,
    shouldDisplayPlaceholderImage: boolean,
    placeholderImage: string | undefined
  ) {
    this.$w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater).data = items?.map(({ id, ...rest }) => ({
      _id: id,
      preset,
      shouldDisplayCurrency,
      shouldDisplayVariantCurrency,
      shouldDisplayZeroPrice,
      shouldDisplayPlaceholderImage,
      placeholderImage,
      ...rest,
    }));
  }

  initLabels($item: I$W, itemData: ShowcasePopulatedItem & { _id: string; currency: string }) {
    const { currentState } = $item(ITEMS_WIDGET_COMPONENT_IDS.itemLabels);

    switch (currentState.id) {
      case LABELS_STATES.TEXT_AND_ICON: {
        const textElementIds = getLabelCompElementIds('itemLabelsTextIconLabelText');
        const iconElementIds = getLabelCompElementIds('itemLabelsTextIconLabelIcon');
        const itemLabelContainerIds = getLabelCompElementIds('itemLabelsTextIconContainer');
        for (let i = 0; i < LABELS_LIMIT; i++) {
          const currentLabel = itemData.labels?.[i];
          const labelElement = $item(ITEMS_WIDGET_COMPONENT_IDS[itemLabelContainerIds[i]]);
          if (currentLabel) {
            labelElement.deleted && labelElement.restore();
            $item(ITEMS_WIDGET_COMPONENT_IDS[textElementIds[i]]).text = currentLabel.name;
            const iconUrl = currentLabel.icon?.url;
            setElementDataAndCollapseIfNeeded(
              $item,
              ITEMS_WIDGET_COMPONENT_IDS[iconElementIds[i]],
              ELEMENT_PROPERTY.SRC,
              iconUrl
            );
          } else {
            labelElement.delete();
          }
        }
        break;
      }
      case LABELS_STATES.ICON_ONLY: {
        getLabelCompElementIds('itemLabelIconOnlyIcon').forEach((iconElementId, i) => {
          const currentLabel = itemData.labels?.[i];
          const iconUrl = currentLabel?.icon?.url;
          setElementDataAndCollapseIfNeeded(
            $item,
            ITEMS_WIDGET_COMPONENT_IDS[iconElementId],
            ELEMENT_PROPERTY.SRC,
            iconUrl
          );
        });
        break;
      }
      case LABELS_STATES.TEXT_ONLY: {
        getLabelCompElementIds('itemLabelTextOnlyText').forEach((textElementId, i) => {
          const currentLabel = itemData.labels?.[i];
          const labelText = currentLabel?.name;
          setElementDataAndCollapseIfNeeded(
            $item,
            ITEMS_WIDGET_COMPONENT_IDS[textElementId],
            ELEMENT_PROPERTY.TEXT,
            labelText
          );
        });
        break;
      }
    }
  }

  removeImagesFromDOMIfNeeded($w: I$W, preset: Preset) {
    $w(ITEMS_WIDGET_COMPONENT_IDS.itemsRepeater).forEachItem(
      (
        $item: I$W,
        itemData: ShowcasePopulatedItem & {
          _id: string;
          currency: string;
          preset: Preset;
          shouldDisplayCurrency: boolean;
          shouldDisplayVariantCurrency: boolean;
          shouldDisplayZeroPrice: boolean;
          shouldDisplayPlaceholderImage: boolean;
          placeholderImage?: string;
        }
      ) => {
        setImageDataOrRemoveFromDOMIfNeeded(
          $item,
          itemData,
          preset,
          getPlaceholderImage({
            shouldDisplayPlaceholderImage: itemData.shouldDisplayPlaceholderImage,
            placeholderImage: itemData.placeholderImage || DEFAULT_PLACEHOLDER_IMAGE,
          }),
          this.isViewer
        );
      }
    );
  }
}
