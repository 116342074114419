import { LabelsController } from './labelsController';
import model from './model';
import type { LabelsWidgetProps } from './types';

export default model.createController(({ $widget, $w, flowAPI }) => {
  const experiments = flowAPI.experiments;
  const isLabelLayouterWidgetExperimentEnabled = experiments.enabled('specs.restaurants.labelsLayouterWidget');
  const labelsController = new LabelsController($w);

  if (isLabelLayouterWidgetExperimentEnabled) {
    $widget.onPropsChanged((_, { data: nextData }: { data: LabelsWidgetProps }) => {
      labelsController.init(nextData);
    });
  }

  return {
    pageReady: async () => {
      isLabelLayouterWidgetExperimentEnabled && labelsController.init($widget.props.data);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
